import axios from 'axios'
import moment from 'moment'

const requestHandler = (config) => {
  const token = localStorage.getItem(
    process.env.REACT_APP_TOKEN_KEY || `saltala_local_token`,
  )
  if (token) {
    config.headers.Authorization = token
  }
  return config
}

const responseHandler = (response) => {
  const {
    data: { data, error, success },
  } = response
  if (success) {
    return data
  } else {
    return Promise.reject(error)
  }
}

const API = {}

const baseURL = process.env.REACT_APP_API_URL || `http://localhost:4000/api/v1`

const axiosInstance = axios.create({
  baseURL,
  validateStatus() {
    return true
  },
})
axiosInstance.interceptors.request.use(requestHandler, (error) =>
  Promise.reject(error),
)

axiosInstance.interceptors.response.use(responseHandler, (error) =>
  Promise.reject(error),
)
//AUTH
API.login = (email, password, subscription) =>
  axiosInstance.post(`admin/user/signin`, { email, password, subscription })

API.logout = (subscription) =>
  axiosInstance.post(`admin/user/signout`, subscription)

//USER
API.getCurrentUser = (subscription) =>
  axiosInstance.post(`admin/user`, subscription)
API.createUser = ({ rut, ...user }) =>
  axiosInstance.post(`admin/user/signup`, {
    rut: rut.slice(0, -1),
    dv: rut.slice(-1),
    ...user,
  })

API.uploadUsers = (file) => {
  const body = new FormData()
  body.append(`users`, file)
  return axiosInstance.post(`admin/user/bulk`, body)
}

API.updateUser = ({ id, rut, ...user }) =>
  axiosInstance.put(`admin/user/update/${id}`, user)

API.userDelete = (id) => axiosInstance.delete(`admin/user/delete/${id}`)

API.userList = ({
  pagination,
  order,
  filter,
  unitId,
  divisionId,
  corporationId,
  scheduleUnitId,
  isDisabled,
}) =>
  axiosInstance.post(`admin/user/list`, {
    pagination,
    order,
    filter,
    unitId,
    divisionId,
    corporationId,
    scheduleUnitId,
    isDisabled,
  })

API.populateUserForm = () => axiosInstance.get(`admin/populateUserForm`)

API.getUserFilters = (params) =>
  axiosInstance.get(`admin/user/filters`, {
    params,
  })

API.getNameAndIdList = (unitId) =>
  axiosInstance.get(`admin/getNameWithIdList/${unitId}`)

//UNIT
API.unitList = (signedUser) => axiosInstance.post(`admin/unit`, signedUser)

API.getRegions = () => axiosInstance.get(`schedule/public/regions`)

API.getCommunes = (regionId) =>
  axiosInstance.get(`schedule/public/communes`, { params: { regionId } })

API.readLine = (id) => axiosInstance.get(`admin/line/${id}`)

API.getLines = (unitId, withUnscopedGetLines) =>
  axiosInstance(`schedule/public/lines`, {
    params: { unitId, withUnscopedGetLines },
  })

API.getLineTypes = () => axiosInstance.get(`/admin/line/lineTypes`)

API.updateLine = (line) => axiosInstance.put(`admin/line/update`, line)

API.createLine = (line) => axiosInstance.post(`admin/line/create`, line)

API.destroyLine = (id) => axiosInstance.delete(`admin/line/delete/${id}`)

API.uploadProfilePicture = (lineId) =>
  `${baseURL}/admin/line/uploadProfilePicture/${lineId}/`

API.createUnit = (unit) => axiosInstance.post(`admin/unit/create`, unit)

API.updateUnit = (unit) => axiosInstance.put(`admin/unit/update`, unit)
API.getFullUnit = (unitId) => axiosInstance.get(`admin/unit/${unitId}`)
API.updateVisualizationOrder = ({ unitId, visualizationOrder }) =>
  axiosInstance.put(`admin/unit/update/${unitId}/visualizationOrder`, {
    visualizationOrder,
  })
API.getUnit = (unitId) => axiosInstance.get(`schedule/public/unit/${unitId}`)

API.getMedicalSpecialties = (unitId) =>
  axiosInstance.get(`admin/unit/medicalSpecialties`, {
    params: { unitId },
  })

API.createSuggestion = ({ lineId, suggestedLineId }) =>
  axiosInstance.post(`admin/line/suggestion/create`, {
    lineId,
    suggestedLineId,
  })

API.destroySuggestion = (suggestionId) =>
  axiosInstance.delete(`admin/line/suggestion/destroy`, {
    params: { suggestionId },
  })

API.uploadPublicFormImage = (unitId) =>
  `${baseURL}/admin/unit/uploadPublicFormImage/${unitId}`

API.uploadTvImage = ({ unitId, file }) => {
  const body = new FormData()
  body.append(`file`, file)
  body.append(`unitId`, unitId)
  return axiosInstance.post(`admin/unit/tvTemplates/uploadTvImage`, body)
}

//Reservations
API.getReservations = ({
  unitId,
  pagination,
  order,
  filter,
  extraFilters,
  dateRange,
  lineId,
}) => {
  const startDate =
    dateRange && dateRange[0]
      ? moment(dateRange[0]).startOf(`day`).format()
      : null
  const endDate =
    dateRange && dateRange[1]
      ? moment(dateRange[1]).endOf(`day`).format()
      : null
  return axiosInstance.get(`admin/reservation`, {
    params: {
      unitId,
      pagination,
      order,
      filter,
      dateRange:
        dateRange && dateRange.length > 0 ? [startDate, endDate] : null,
      extraFilters,
      lineId,
    },
  })
}

API.createReservationLock = ({ timeRange, lineId }) => {
  const params = { lineId }
  const [startTime, endTime] = timeRange
  params.startTime = moment(startTime)
  params.endTime = moment(endTime)
  return axiosInstance.post(`admin/reservation/createReservationLock`, params)
}

API.getLineReservationTimeline = (lineId) =>
  axiosInstance.get(`admin/reservation/${lineId}/timeline`)

API.revertReservationLock = (payload) =>
  axiosInstance.post(`admin/reservation/revertReservationLock`, payload)

API.populateReservations = ({ timeRange, ...params }) => {
  const [startTime, endTime] = timeRange
  params.startTime = moment(startTime).startOf(`day`)
  params.endTime = moment(endTime).endOf(`day`)
  return axiosInstance.post(`admin/reservation/populate`, params)
}

API.getScheduleTemplates = (lineId) =>
  axiosInstance.get(`admin/reservation/scheduleTemplate`, {
    params: { lineId },
  })

API.createScheduleTemplate = (params) =>
  axiosInstance.post(`admin/reservation/scheduleTemplate/create`, params)
API.updateScheduleTemplate = (params) =>
  axiosInstance.post(`admin/reservation/scheduleTemplate/update`, params)
//Monitor
API.readSupStat = ({ byAttentions }) =>
  axiosInstance.post(`admin/monitor`, { byAttentions })

API.getReservationMonitor = ({ unitId }) =>
  axiosInstance.get(`admin/reservationmonitor/${unitId}`)
//Unit

//MODULES
API.getModules = (unitId) =>
  axiosInstance.get(`admin/module`, { params: { unitId } })
API.getModuleCallTypes = () => axiosInstance.get(`admin/module/callTypes`)
API.getModule = (moduleId) => axiosInstance.get(`admin/module/${moduleId}`)
API.createModule = (values) => axiosInstance.post(`admin/module/create`, values)
API.editModule = (values) => axiosInstance.put(`admin/module/update`, values)
API.deleteModule = (id) => axiosInstance.delete(`admin/module/delete/${id}`)
API.uploadModules = (file) => {
  const body = new FormData()
  body.append(`modules`, file)
  return axiosInstance.post(`admin/module/bulk`, body)
}

//STADISTICS

API.getStatisticData = ({
  agregationLevel,
  dateRange,
  dataType,
  isTwoDimensional,
  unitId = null,
  lineIds,
}) => {
  const [startDate, endDate] = dateRange
  startDate.set({ hour: 0, minute: 0, second: 0 })
  endDate.set({ hour: 23, minute: 59, second: 59 })
  return axiosInstance.post(`admin/statistics`, {
    agregationLevel,
    isTwoDimensional,
    startDate,
    endDate,
    unitId,
    dataType,
    lineIds,
  })
}

API.getHistoricalAttentionsByUnit = ({ dateRange, unitId, lineIds }) => {
  const [startDate, endDate] = dateRange
  startDate.set({ hour: 0, minute: 0, second: 0 })
  endDate.set({ hour: 23, minute: 59, second: 59 })

  return axiosInstance.get(`admin/statistics/historicalAttentions`, {
    params: {
      startDate: startDate.format(),
      endDate: endDate.format(),
      unitId,
      lineIds,
    },
  })
}

API.getUnitsAndDependencies = () =>
  axiosInstance.get(`admin/statistics/filters`)
//CORPORATIONS
API.getCorporations = () => axiosInstance.get(`admin/corporation/list`)

API.createCorporation = (corporation) =>
  axiosInstance.post(`admin/corporation/create`, corporation)

API.editCorporation = ({ id, ...corporation }) =>
  axiosInstance.put(`admin/corporation/update/${id}`, corporation)

API.getTheme = (corporationId) =>
  axiosInstance.get(`admin/corporation/theme`, { params: { corporationId } })

API.uploadCorporationImage = ({ corporationId, imageType }) =>
  `${baseURL}/admin/corporation/uploadImage/${corporationId}/${imageType}`

//DIVISIONS
API.getDivisions = (signedUser) =>
  axiosInstance.post(`admin/division/list`, signedUser)

API.createDivision = (division) =>
  axiosInstance.post(`admin/division/create`, division)

API.editDivision = (division) =>
  axiosInstance.put(`admin/division/update/${division.id}`, division)

API.getTimezones = () => axiosInstance.get(`admin/division/timezones`)
//SCHEDULES
//PROFESSIONAL
API.getProfessionalAppointments = (date) =>
  axiosInstance.get(`schedule/professional/appointments?date=${date}`)
API.startAttention = (payload) =>
  axiosInstance.patch(`schedule/professional/startAttention`, payload)
API.endAttention = (payload) =>
  axiosInstance.patch(`schedule/professional/endAttention`, payload)

API.createScheduleGenerator = (payload) =>
  axiosInstance.post(`admin/scheduleUser/createScheduleGenerator`, payload)

API.getScheduleGenerator = (payload) =>
  axiosInstance.get(`admin/scheduleUser/getScheduleGenerator`, {
    params: { ...payload },
  })

API.deleteScheduleGenerator = (id) =>
  axiosInstance.delete(`admin/scheduleUser/deleteScheduleGenerator/${id}`)

//SHARED
API.makeAppointment = (payload) =>
  axiosInstance.patch(`schedule/receptionist/makeAppointment`, payload)
API.getAvailableAppointments = ({
  date,
  scheduleUnitId,
  appointmentStatuses = [1],
  scheduleUserId = null,
  onlyPublic = false,
  withPatientData = false,
  withScheduleUnitData = false,
  withOverbooks = false,
}) =>
  axiosInstance.get(`schedule/receptionist/getAvailable`, {
    params: {
      date,
      scheduleUnitId,
      appointmentStatuses,
      scheduleUserId,
      onlyPublic,
      withPatientData,
      withScheduleUnitData,
      withOverbooks,
    },
  })
API.getNextAvailableAppointment = ({
  scheduleUserId = null,
  scheduleUnitId,
  onlyPublic = false,
}) =>
  axiosInstance.get(`schedule/public/nextAppointment`, {
    params: { scheduleUnitId, onlyPublic, scheduleUserId },
  })
API.getPatientAppointments = (patientRut) =>
  axiosInstance.get(`schedule/receptionist/getPatientAppointments`, {
    params: {
      patientRut,
    },
  })

//SHARED
//RECEPTIONIST
API.getReceptionistAppointments = (date, scheduleUnitId) =>
  axiosInstance.get(`schedule/receptionist/appointments`, {
    params: {
      date,
      scheduleUnitId,
    },
  })

API.releaseAppointment = (payload) =>
  axiosInstance.patch(`schedule/receptionist/release`, payload)
API.deleteOverbook = (payload) =>
  axiosInstance.delete(`schedule/receptionist/deleteOverbook`, {
    data: payload,
  })
API.overbook = (payload) =>
  axiosInstance.post(`schedule/receptionist/overbook`, payload)
API.registerAttendance = (payload) =>
  axiosInstance.patch(`schedule/receptionist/attendance`, payload)
API.registerAbsent = (payload) =>
  axiosInstance.patch(`schedule/receptionist/absent`, payload)
API.updatePatient = (payload) =>
  axiosInstance.patch(`schedule/receptionist/updatePatient`, payload)

API.findAppointments = ({ patientRut, scheduleUnitId, userId }) =>
  axiosInstance.get(`schedule/public/findAppointments`, {
    params: {
      patientRut,
      userId,
      scheduleUnitId,
    },
  })
//VALIDATION

API.validateRut = (rut, unitId) =>
  axiosInstance.get(`integration/lascondes/validateneighbor`, {
    params: { rut, unitId },
  })

API.sendConfirmationEmail = (payload) =>
  axiosInstance.post(`schedule/receptionist/sendConfirmationMail`, payload)

//SCHEDULE
API.getScheduleUnits = () => axiosInstance.get(`admin/scheduleUnit/getAll`)

API.findScheduleUnit = (unitId) =>
  axiosInstance.get(`admin/scheduleUnit/${unitId}`)

API.getScheduleUsers = (unitId) =>
  axiosInstance.get(`admin/scheduleUser/getAll?scheduleUnitId=${unitId}`)

API.getScheduleUser = (userId) =>
  axiosInstance.get(`admin/scheduleUser/select?scheduleUserId=${userId}`)

API.setScheduleUser = (payload) =>
  axiosInstance.patch(`admin/scheduleUser/update`, payload)

API.createException = (payload) =>
  axiosInstance.post(`admin/scheduleUser/createException`, payload)

API.createLock = (payload) =>
  axiosInstance.post(`admin/scheduleUser/createLock`, payload)

API.getLocks = (scheduleUserId) =>
  axiosInstance.get(`admin/scheduleUser/scheduleLocks`, {
    params: {
      scheduleUserId,
    },
  })

API.getExceptions = (scheduleUserId) =>
  axiosInstance.get(`admin/scheduleUser/scheduleExceptions`, {
    params: {
      scheduleUserId,
    },
  })

API.revertLock = (payload) =>
  axiosInstance.patch(`admin/scheduleUser/revertScheduleLock`, payload)

API.revertException = (payload) =>
  axiosInstance.patch(`admin/scheduleUser/revertScheduleException`, payload)

API.createScheduleUnit = (payload) =>
  axiosInstance.post(`admin/scheduleUnit/create`, payload)

API.updateScheduleConfig = (config) =>
  axiosInstance.patch(`admin/scheduleUnit/update`, config)
API.getScheduleMonitor = (dateRange) => {
  const [startDate, endDate] = dateRange
  startDate.set({ hour: 0, minute: 0, second: 0 })
  endDate.set({ hour: 23, minute: 59, second: 59 })
  return axiosInstance.get(`admin/scheduleUser/monitor`, {
    params: {
      startDate: startDate.startOf(`day`).format(),
      endDate: endDate.endOf(`day`).format(),
    },
  })
}

// Monitor de Sala
API.getRoomMonitorForLines = (unitId) =>
  axiosInstance.get(`admin/roommonitor/lines`, { params: { unitId } })

API.getRoomMonitorForModules = (unitId) =>
  axiosInstance.get(`admin/roommonitor/modules`, { params: { unitId } })

API.getTicketAttentions = ({ ticketId, filter, unitId }) =>
  axiosInstance.get(`admin/ticketdata`, {
    params: { ticketId, filter, unitId },
  })

API.getModuleAdmissionist = ({ userId, filter }) =>
  axiosInstance.get(`admin/moduleadmissionistdata`, {
    params: { userId, filter },
  })

//
API.uploadFile = (unitId) => `${baseURL}/admin/unit/uploadVideo/${unitId}`

//PHARMACY
API.getRejectionMotives = (unitId) =>
  axiosInstance.get(`admin/unit/rejectionMotives`, { params: { unitId } })

API.createRejectionMotive = (payload) =>
  axiosInstance.post(`admin/unit/rejectionMotives/create`, payload)

API.updateRejectionMotive = (payload) =>
  axiosInstance.put(`admin/unit/rejectionMotives/update`, payload)

API.deleteRejectionMotive = (id) =>
  axiosInstance.delete(`admin/unit/rejectionMotives/destroy`, {
    params: { id },
  })

API.getDeliveryPeople = (unitId) =>
  axiosInstance.get(`admin/unit/deliveryPeople`, { params: { unitId } })

API.createDeliveryPeople = (payload) =>
  axiosInstance.post(`admin/unit/deliveryPeople/create`, payload)

API.updateDeliveryPeople = (payload) =>
  axiosInstance.put(`admin/unit/deliveryPeople/update`, payload)

API.deleteDeliveryPeople = (id) =>
  axiosInstance.delete(`admin/unit/deliveryPeople/destroy`, {
    params: { id },
  })

API.generateDeliveryRequest = (payload) =>
  axiosInstance.post(`schedule/public/deliveries/createRequest`, payload)

API.createPrescription = (payload) =>
  axiosInstance.post(`schedule/public/prescription/create`, payload)

API.deleteValidPatient = (validPatientId) =>
  axiosInstance.delete(`admin/validPatient/delete`, {
    params: { validPatientId },
  })

API.editValidPatient = (patient) =>
  axiosInstance.put(`admin/validPatient/update`, { ...patient })

API.createValidPatient = (patient) =>
  axiosInstance.post(`admin/validPatient/create`, { ...patient })

API.getValidPatients = (filters) =>
  axiosInstance.post(`admin/validPatients/list`, filters)

API.getValidPatient = (payload) =>
  axiosInstance.get(`schedule/public/validPatient`, { params: payload })

API.getPickupReservations = ({ unitId, statuses }) =>
  axiosInstance.get(`schedule/pickups`, {
    params: { unitId, statuses },
  })
API.confirmReservation = (payload) =>
  axiosInstance.patch(`schedule/public/confirmReservation`, payload)
API.cancelReservation = (payload) =>
  axiosInstance.patch(`admin/reservation/cancelReservation`, payload)

API.manageReservation = (payload) =>
  axiosInstance.patch(`schedule/manageReservation`, payload)

API.getDeliveryRequests = ({
  unitId,
  filter,
  pagination,
  deliveryRequestStatusId,
  extraFilters,
  order,
  requestDates,
}) =>
  axiosInstance.get(`schedule/deliveryRequests`, {
    params: {
      unitId,
      filter,
      pagination,
      order,
      deliveryRequestStatusId,
      requestDates,
      ...extraFilters,
    },
  })

API.getDeliveries = ({
  unitId,
  statuses,
  filter,
  pagination,
  deliveryStatusId,
  extraFilters,
  deliveryDates,
  order,
}) =>
  axiosInstance.get(`schedule/deliveries`, {
    params: {
      unitId,
      statuses,
      filter,
      pagination,
      order,
      deliveryStatusId,
      deliveryDates,
      ...extraFilters,
    },
  })

API.updateDeliveryRequest = (payload) =>
  axiosInstance.put(`schedule/deliveries/updateRequest`, payload)
API.updateDelivery = (payload) =>
  axiosInstance.put(`schedule/deliveries/updateDelivery`, payload)

API.multiSend = ({ deliveries, notifyPatient, withPartialDelivery }) =>
  axiosInstance.put(`schedule/deliveries/multisend`, {
    deliveries,
    notifyPatient,
    withPartialDelivery,
  })
API.uploadHomeHospitalizationDeliveries = ({ file, unitId }) => {
  const formData = new FormData()
  formData.append(`file`, file)
  formData.append(`unitId`, unitId)
  return axiosInstance.post(`schedule/deliveries/upload`, formData)
}
API.confirmPickup = (payload) =>
  axiosInstance.post(`schedule/pickup/create`, payload)

API.editPickup = (payload) =>
  axiosInstance.post(`schedule/pickup/edit`, payload)

API.getHealthInstitutions = (payload) =>
  axiosInstance.get(`schedule/public/healthinstitutions`, { params: payload })

API.getPickups = ({
  unitId,
  pickupStatusId,
  pagination,
  order,
  filter,
  extraFilters,
}) => {
  if (extraFilters?.reservationDate) {
    const formattedReservationDate = [
      extraFilters?.reservationDate?.[0]?.format(`YYYY-MM-DD`),
      extraFilters?.reservationDate?.[1]?.format(`YYYY-MM-DD`),
    ]
    extraFilters = {
      ...extraFilters,
      reservationDate: formattedReservationDate,
    }
  }
  return axiosInstance.get(`schedule/getpickups`, {
    params: {
      unitId,
      pickupStatusId,
      pagination,
      order,
      filter,
      ...extraFilters,
    },
  })
}

API.editPrescription = (payload) =>
  axiosInstance.patch(`/schedule/prescription/edit`, payload)
//MEDICAL SPECIALTIES
API.getMedicalSpecialties = (unitId) =>
  axiosInstance.get(`admin/unit/medicalSpecialties`, { params: { unitId } })

API.createMedicalSpecialty = (payload) =>
  axiosInstance.post(`admin/unit/medicalSpecialty/create`, payload)

API.updateMedicalSpecialty = (payload) =>
  axiosInstance.put(`admin/unit/medicalSpecialty/update`, payload)

API.deleteMedicalSpecialty = (id) =>
  axiosInstance.delete(`admin/unit/medicalSpecialty/destroy`, {
    params: { id },
  })
//ACTIVITY TYPES
API.getActivityTypes = (unitId) =>
  axiosInstance(`admin/unit/activityTypes`, { params: { unitId } })

API.createActivityType = (payload) =>
  axiosInstance.post(`admin/unit/activityType/create`, payload)

API.updateActivityType = (payload) =>
  axiosInstance.put(`admin/unit/activityType/update`, payload)

API.deleteActivityType = (id) =>
  axiosInstance.delete(`admin/unit/activityType/destroy`, {
    params: { id },
  })
//LINE CATEGORIES
API.getLineCategories = (unitId) =>
  axiosInstance.get(`admin/lineCategory`, { params: { unitId } })

API.createLineCategory = (lineCategory) =>
  axiosInstance.post(`admin/lineCategory/create`, lineCategory)

API.editLineCategory = (lineCategory) =>
  axiosInstance.put(
    `admin/lineCategory/update/${lineCategory.id}`,
    lineCategory,
  )

API.deleteLineCategory = (lineCategoryId) =>
  axiosInstance.delete(`admin/lineCategory/delete/${lineCategoryId}`)

API.assignLineCategory = (payload) =>
  axiosInstance.post(`admin/lineCategory/assign`, payload)

// STOCKS
API.getStocks = (payload) =>
  axiosInstance.get(`local/stock/all`, { params: payload })
API.getStock = (payload) =>
  axiosInstance.get(`local/stock/`, { params: payload })
API.createStock = (payload) => axiosInstance.post(`local/stock/create`, payload)
API.editStock = ({ ...payload }) =>
  axiosInstance.post(`local/stock/update`, payload)

// PROGRAMS
API.getPrograms = (unitId) =>
  axiosInstance.get(`/admin/programs`, { params: { unitId } })
API.createProgram = ({
  unitId,
  programName,
  programProtocols,
  typeProgramId,
  notifyToAdmin,
  contactMail,
  confirmMessage,
  cancelMessage,
  confirmImageLocation,
  cancelImageLocation,
  pdfLocation,
  questions,
  customQuestion,
  schema,
}) =>
  axiosInstance.post(`/admin/program/create`, {
    unitId,
    programName,
    programProtocols,
    typeProgramId,
    notifyToAdmin,
    contactMail,
    confirmMessage,
    cancelMessage,
    confirmImageLocation,
    cancelImageLocation,
    pdfLocation,
    questions,
    customQuestion,
    schema,
  })
API.imageUpload = ({
  confirmImageInput,
  cancelImageInput,
  pdfInput,
  selectedCorporation,
}) => {
  const data = new FormData()
  data.append(`confirmImageInput`, confirmImageInput)
  data.append(`cancelImageInput`, cancelImageInput)
  data.append(`pdfInput`, pdfInput)
  data.append(`selectedCorporation`, `${selectedCorporation}`)
  return axiosInstance.post(`/admin/program/imageUpload`, data)
}
API.imageDelete = ({ deleteType, program }) => {
  axiosInstance.patch(`admin/program/imageDelete`, { deleteType, program })
}

// TYPE PROGRAMS
API.getTypePrograms = (unitId) =>
  axiosInstance.get(`/admin/typePrograms`, { params: { unitId } })
API.updateProgram = (body) => axiosInstance.patch(`/admin/program/update`, body)

// PROTOCOLS
API.updateProgramProtocol = ({
  programId,
  programProtocolId,
  step,
  scheduledHours,
  message,
  protocolId,
  includePatientName,
}) =>
  axiosInstance.put(`admin/program-protocol/update/${programProtocolId}`, {
    programId,
    step,
    scheduledHours,
    message,
    protocolId,
    includePatientName,
  })

API.createProgramProtocol = ({
  protocolId,
  step,
  scheduledHours,
  message,
  programId,
  includePatientName,
}) =>
  axiosInstance.post(`admin/program-protocol/create`, {
    protocolId,
    step,
    scheduledHours,
    message,
    programId,
    includePatientName,
  })

API.deleteProgramProtocol = (programProtocolId) =>
  axiosInstance.delete(`admin/program-protocol/delete/${programProtocolId}`)

//Composed mode priority
API.getComposedPriorityData = (unitId) =>
  axiosInstance.get(`/admin/unit/composedPriority`, { params: { unitId } })

API.createAdmissionistProfile = (payload) =>
  axiosInstance.post(`admin/unit/admissionistProfile`, payload)

API.updateAdmissionistProfile = (payload) =>
  axiosInstance.put(`admin/unit/admissionistProfile`, payload)

API.deleteAdmissionistProfile = (id) =>
  axiosInstance.delete(`admin/unit/admissionistProfile`, {
    params: { id },
  })
API.createAdmissionistSchedule = (payload) =>
  axiosInstance.post(`admin/unit/admissionistSchedule`, payload)

API.updateAdmissionistSchedule = (payload) =>
  axiosInstance.put(`admin/unit/admissionistSchedule`, payload)

API.deleteAdmissionistSchedule = (id) =>
  axiosInstance.delete(`admin/unit/admissionistSchedule`, {
    params: { id },
  })

API.updsertAdmissionistScheduleProfiles = (payload) =>
  axiosInstance.put(`admin/unit/admissionistScheduleProfile`, payload)

// ATTENTION MOTIVES
API.getMotives = ({ unitId, lineId }) =>
  axiosInstance.get(`admin/unit/motives`, { params: { unitId, lineId } })
API.updateMotive = ({ id, description }) =>
  axiosInstance.patch(`admin/unit/motives/${id}`, { description })
API.createMotive = ({ description, unitId, lineId }) =>
  axiosInstance.post(`admin/unit/motives`, { description, unitId, lineId })
API.deleteMotive = (id) => axiosInstance.delete(`admin/unit/motives/${id}`)

// TV TEMPLATES
API.getMonitors = () => axiosInstance.get(`admin/unit/monitors`)
API.getTvTemplates = (unitId) =>
  axiosInstance.get(`admin/unit/tvtemplates/${unitId}`)

API.createTvTemplate = (payload) =>
  axiosInstance.post(`admin/unit/tvtemplates`, payload)

API.updateTvTemplate = (payload) =>
  axiosInstance.put(`admin/unit/tvtemplates`, payload)

API.deleteTvTemplate = (id) =>
  axiosInstance.delete(`admin/unit/tvtemplates/${id}`)

// Totem Layout
API.getTotemLayout = (unitId) =>
  axiosInstance.get(`admin/totemLayouts/${unitId}`)

API.getTotemLayoutTypes = () => axiosInstance.get(`admin/totemLayouts/types`)

API.uploadTotemLayoutImageUrl = () =>
  `${baseURL}/admin/totemLayouts/uploadImage/`

API.updateTotemLayout = ({
  unitId,
  informationConfig,
  isActive,
  layoutTypeId,
  totemLayoutId,
  disabledButton,
}) =>
  axiosInstance.patch(`/admin/totemLayouts`, {
    unitId,
    informationConfig,
    isActive,
    layoutTypeId,
    totemLayoutId,
    disabledButton,
  })

API.createTotemLayout = ({
  unitId,
  informationConfig,
  isActive,
  layoutTypeId,
  disabledButton,
}) =>
  axiosInstance.post(`/admin/totemLayouts`, {
    unitId,
    informationConfig,
    isActive,
    layoutTypeId,
    disabledButton,
  })

// Ticket layout
API.getTicketLayoutTypes = () => axiosInstance.get(`admin/ticketLayouts/types`)

//Tags - LineTags

API.getTagsByCorporation = (corporationId) =>
  axiosInstance.get(`admin/adminTags/get`, { params: { corporationId } })
API.getTagsAndLines = () => axiosInstance.get(`admin/adminTags/getTagsAndLines`)
API.getTagsAndLinesByCorporation = (corporationId) =>
  axiosInstance.get(`admin/adminTags/getTagsAndLinesByCorporation`, {
    params: { corporationId },
  })

API.getLineTagsAssociateds = (lineId) =>
  axiosInstance.get(`admin/admintags/getLineTagsAssociateds`, {
    params: { lineId },
  })

API.associateLineTag = ({ lineId, tagName, tagId }) =>
  axiosInstance.post(`admin/admintags/createAssociation`, {
    lineId,
    tagName,
    tagId,
  })

API.deleteLineTag = (lineId, tagId) =>
  axiosInstance.delete(`admin/admintags/deleteAssociation`, {
    params: { lineId, tagId },
  })

API.getExclusions = (unitId) =>
  axiosInstance.get(`admin/autopago/getExclusionRules`, { params: { unitId } })

API.deleteExclusion = (id) =>
  axiosInstance.delete(`admin/autopago/deleteExclusionRule`, {
    params: { id },
  })

API.createExclusion = (payload) =>
  axiosInstance.post(`admin/autopago/createExclusionRule`, payload)

API.updateExclusion = (payload) =>
  axiosInstance.patch(`admin/autopago/updateExclusionRule`, payload)

API.downloadPrivateFile = (url) => {
  const token = localStorage.getItem(
    process.env.REACT_APP_TOKEN_KEY || `saltala_local_token`,
  )
  return axios.get(url, {
    headers: {
      Authorization: token,
    },
  })
}

// Autopago

/// Registros
API.getAutopagoLogs = (params) =>
  axiosInstance.get(`admin/autopago/logs`, {
    params,
  })

API.getAutopagoLog = (detailUrl) => axiosInstance.get(`admin/${detailUrl}`)

export default API
